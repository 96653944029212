<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5 mb-3">クレジットカード払い（オンライン決済）の設定</h1>
        <banner-hint>
          クレジットカードによるオンライン決済の説明・手順と決済URLを登録できます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
      >
        <v-card
          class="pa-7 mb-5"
          elevation="1"
        >
          <!-- 入力 -->
          <v-form ref="form-credit">
            <v-row class="px-2">
              <v-col cols="12">
                <v-sheet class="d-flex flex-column">
                  <v-textarea
                    v-model.trim="creditPayment.description"
                    filled
                    auto-grow
                    counter="200"
                    label="説明文"
                    required
                    :rules="[valiRules.required, valiRules.max200]"
                  ></v-textarea>

                  <!-- 定型文ボタン -->
                  <btn-boilerplate
                    class="mt-2"
                    :apiAdmin="apiAdmin"
                    :shopData="shopData"
                    :boilerplateName="'credit_payment_description'"
                    :inputField.sync="creditPayment.description"
                    :btnClass="'align-self-end'"
                    @reset="$emit('reset')"
                  ></btn-boilerplate>
              </v-sheet>
              </v-col>
              <v-col cols="12"
              >
                <v-sheet class="d-flex flex-column">
                  <v-textarea
                    v-model.trim="creditPayment.instruction"
                    filled
                    auto-grow
                    counter="500"
                    label="決済手順（必要であれば）"
                    :rules="[valiRules.max500]"
                  ></v-textarea>

                  <!-- 定型文ボタン -->
                  <btn-boilerplate
                    class="mt-2"
                    :apiAdmin="apiAdmin"
                    :shopData="shopData"
                    :boilerplateName="'credit_payment_instruction'"
                    :inputField.sync="creditPayment.instruction"
                    :btnClass="'align-self-end'"
                    @reset="$emit('reset')"
                  ></btn-boilerplate>
                </v-sheet>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="creditPayment.transaction_url"
                  required
                  label="決済用URL"
                  :rules="[valiRules.required, valiRules.url, valiRules.max150]"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex justify-end align-center">
                <v-btn
                  v-if="creditPayment.create"
                  depressed
                  color="accent"
                  @click="createRow(creditPayment)"
                >登録</v-btn>
                <v-btn
                  v-else
                  depressed
                  color="primary"
                  @click="updateRow(creditPayment)"
                >更新</v-btn>
                <v-btn
                  class="ml-2"
                  text
                  depressed
                  color="primary"
                  @click="deleteRow()"
                >削除</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";
import BtnSetBoilerplate from '@/components/_BtnSetBoilerplate.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'btn-boilerplate': BtnSetBoilerplate,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      creditPayment: {create: true},
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getCreditPayments(),
      ])

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getCreditPayments() {
      return this.publicApi.getReqSitePublic('credit-payment/').then( data => {
        if (!data || !data.instruction) return

        this.creditPayment = {...data, ...{create: false}}
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(credit) {
      if (!this.$refs['form-credit'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append("description", credit.description);
      formData.append("instruction", credit.instruction);
      formData.append("transaction_url", credit.transaction_url);

      this.adminApi.apiReqWithData('POST', 'create/credit-payment/', formData).then(() => {
        credit.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(credit) {
      if (!this.$refs['form-credit'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/credit-payment/'
      const formData = new FormData();
      formData.append("description", credit.description);
      formData.append("instruction", credit.instruction);
      formData.append("transaction_url", credit.transaction_url);

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow() {
      const apiPath = 'delete/credit-payment/'

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.$refs['form-credit'].reset()

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
</style>
