<template>
  <v-dialog
    v-model="openConfirmation"
    width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="btnClass"
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="openConfirmation = true"
      >定型文</v-btn>
    </template>
    <v-card class="pt-7 pb-5">
      <v-card-text >
        <span>定型文を挿入しますか？</span>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          outlined small
          color="primary"
          @click="setBoilerplate"
        >
          はい
        </v-btn>
        <v-btn
          text small
          color="primary"
          @click="openConfirmation = false"
        >
          いいえ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'

export default {
  components: {
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    boilerplateName: {
      type: String,
      required: true
    },
    btnClass: {
      type: String,
    },
    inputField: {
      type: String,
    },
  },

  data() {
    return {
      openConfirmation: false,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    };
  },

  created() {
    this.adminApi.setToken(this.serverToken)
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  methods: {
    //定型文セット
    setBoilerplate() {
      this.adminApi.getReqWithAuth('boilerplate/' + this.boilerplateName).then(bp => {
        if (!bp.boilerplate) {
          this.snackbar.color = 'error'
          this.snackbar.message = $literals.MESSAGE.failedGetBoilerplate
          this.snackbar.open = true
        } else {
          this.$emit('update:inputField', bp.boilerplate)
          // this.creditPayment[column] = bp.boilerplate
        }
      })
      .catch(error => {
        if (CheckTokenError(error)) this.$emit('reset')
        else {
          alert($literals.MESSAGE.failedApiGet + error.response.data + error.response.status)
        }
      })
      .then(() => this.openConfirmation = false )
    },
  }
};
</script>

<style scoped>
</style>
